import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import {
    Column,
    FluidImage,
    GridContainer,
    Row,
    SelectionSet,
    SelectionSetLabel,
    SelectionSetTileContents,
    SelectionSetTileDescription,
    SelectionSetTileImageContainer,
    SelectionSetTileName,
    Typography,
} from '@vp/swan';
import { bugtracker } from '@99designs/design-services-common';
import {
    FieldDisplayWrapper,
    FieldView,
    ProductCard,
    fieldGroups,
    findProductOptions,
} from '@99designs/feature-design-services-brief';
import { getIsProductOptionsFeatureFlagEnabled } from '@99designs/feature-design-services-brief';
import { ClientConfiguredField, PrintProductOption } from '@99designs/graph-utils/types';
import { __ } from '@99designs/i18n';
import { BriefFragment, useCollaborationContext } from '../../context';
import { usePrintProductConfiguratorQuery } from './Brief.generated';
import { BriefLayout } from './BriefLayout';

export const Brief = () => {
    return (
        <>
            <Helmet>
                <title>{__('Vistaprint | Design Services Project Brief')}</title>
            </Helmet>
            <BriefLayout>{(data) => <BriefUI {...data} />}</BriefLayout>
        </>
    );
};

/**
 * Brief UI component
 * This is a placeholder used for testing the brief data fetching
 */
export const BriefUI = ({
    brief,
    collaborationId,
    groups,
}: {
    brief: BriefFragment;
    collaborationId: string;
    groups: ReturnType<typeof fieldGroups>;
}) => {
    const [productOptions, setProductOptions] = useState<PrintProductOption[]>([]);
    const [customerSelectedOptions, setCustomerSelectedOptions] = useState<PrintProductOption[]>(
        []
    );
    const [isProductOptionsFeatureFlagEnabled, setIsProductOptionsFeatureFlagEnabled] =
        useState(false);

    useEffect(() => {
        const clientSelectedOptions = getClientSelectedOptions(brief);
        const matchedProductOptions = productOptions.filter((option) =>
            Object.keys(clientSelectedOptions).includes(option.key)
        );

        if (matchedProductOptions.length > 0) {
            setCustomerSelectedOptions(
                findProductOptions(clientSelectedOptions, matchedProductOptions)
            );
        }
    }, [productOptions, brief]);

    useEffect(() => {
        const fetchFlag = async () => {
            const flag = await getIsProductOptionsFeatureFlagEnabled();
            setIsProductOptionsFeatureFlagEnabled(flag);
        };

        fetchFlag();
    }, []);

    const { loading: configuratorLoading, error: configuratorError } =
        usePrintProductConfiguratorQuery({
            variables: {
                input: {
                    mpvId: brief.product?.fulfilmentMpvId || '',
                    tenant: 'vistaprint',
                    isProductOptionsFeatureFlagEnabled: isProductOptionsFeatureFlagEnabled ?? false,
                },
            },
            skip: !brief.product || !brief.product.key || !brief.product.fulfilmentMpvId,
            fetchPolicy: 'cache-and-network',
            onCompleted: (data) => {
                setProductOptions(data.printProductConfigurator.productOptions);
            },
            onError: (e) => {
                bugtracker.notify(
                    new Error(`Failed to get product configurator: ${e.message}`),
                    (event) => {
                        event.addMetadata('debug info', {
                            mpvId: brief.product?.fulfilmentMpvId,
                            productKey: brief.product?.key,
                        });
                    }
                );
            },
        });

    const collab = useCollaborationContext();
    const printProductOptions = collab.printProduct.productOptionsJson
        ? (JSON.parse(collab.printProduct.productOptionsJson) as Record<string, string>)
        : undefined;
    const productType = collab.mpvId
        .replace(/([a-z])([A-Z])/g, '$1 $2')
        .replace(/\b\w/g, (c) => c.toUpperCase());

    return (
        <GridContainer mt={9} mb={9}>
            <Row>
                <Column span={12} px={'5'}>
                    <Typography fontSkin="title-section" px={'5'} mb={'7'}>
                        {__('Your design project details')}
                    </Typography>
                    {collaborationId && (
                        <FieldDisplayWrapper skin="standard" title={__('Request ID')}>
                            <Typography>{collaborationId}</Typography>
                        </FieldDisplayWrapper>
                    )}
                    {printProductOptions && productType && (
                        <FieldDisplayWrapper skin="standard" title={__('Selected product options')}>
                            <Typography>Product: {productType}</Typography>
                            {Object.entries(printProductOptions).map(([key, value]) => (
                                <Typography key={key}>
                                    {key}: {value}
                                </Typography>
                            ))}
                        </FieldDisplayWrapper>
                    )}
                    {(brief.product || brief.request?.expertService) && (
                        <ProductCard
                            product={brief.product ?? undefined}
                            expertService={brief.request?.expertService ?? undefined}
                            hideImage
                            withDesignerEarnings={
                                collab.isFreelance && collab.viewerRole !== 'VIEWER_ROLE_CLIENT'
                            }
                            fullWidth
                        />
                    )}
                    {brief.workName && (
                        <FieldDisplayWrapper
                            skin="standard"
                            title={__("The template you've started with")}
                        >
                            <Typography fontSize="small">{brief.workName}</Typography>
                        </FieldDisplayWrapper>
                    )}
                    {groups.map((group) => {
                        return group.fields.map((field) => {
                            return (
                                <FieldView
                                    skin="standard"
                                    key={field.id}
                                    field={field}
                                    colWidthOverride={6}
                                />
                            );
                        });
                    })}
                    {!configuratorLoading &&
                        !configuratorError &&
                        customerSelectedOptions.length > 0 && (
                            <FieldDisplayWrapper
                                skin="standard"
                                title={__('Select your product configuration')}
                            >
                                <SelectionSet skin="tiles-mini">
                                    {customerSelectedOptions.map(
                                        (selectedPrintProductOption, i) => (
                                            <ProductOptionDisplay
                                                key={`${selectedPrintProductOption?.key}-${i}`}
                                                selectedPrintProductOption={
                                                    selectedPrintProductOption
                                                }
                                            />
                                        )
                                    )}
                                </SelectionSet>
                            </FieldDisplayWrapper>
                        )}
                </Column>
            </Row>
        </GridContainer>
    );
};

export const getClientSelectedOptions = (brief: BriefFragment): { [key: string]: string } => {
    const productOptionsField = brief.dynamicFields.find(
        (field) => field.id === 'productOptions'
    ) as ClientConfiguredField | undefined;

    if (!productOptionsField || !productOptionsField.clientConfiguredValue) {
        return {};
    }

    return JSON.parse(productOptionsField.clientConfiguredValue) as { [key: string]: string };
};

interface ProductOptionDisplayProps {
    selectedPrintProductOption: PrintProductOption;
}

const ProductOptionDisplay: React.FC<ProductOptionDisplayProps> = ({
    selectedPrintProductOption,
}) => {
    return (
        <SelectionSetLabel>
            {selectedPrintProductOption?.values[0].imageUrl && (
                <SelectionSetTileImageContainer>
                    <FluidImage
                        src={selectedPrintProductOption.values[0].imageUrl}
                        alt={selectedPrintProductOption.values[0].key}
                    />
                </SelectionSetTileImageContainer>
            )}
            <SelectionSetTileContents>
                <SelectionSetTileName>{selectedPrintProductOption?.key}</SelectionSetTileName>
                <SelectionSetTileDescription>
                    {selectedPrintProductOption?.values[0].name}
                </SelectionSetTileDescription>
            </SelectionSetTileContents>
        </SelectionSetLabel>
    );
};
