import { Box, Button, FlexBox } from '@vp/swan';
import { CollaborationFile } from '@99designs/graph-utils/types';
import { __ } from '@99designs/i18n';
import { DesignRevisionMeta, PinComment, PinCommentList, TextSection } from '../../ui';
import { ReferenceImageSection } from './components/ReferenceImageSection';

export interface DesignRevisionFeedbackPanelProps {
    version: string;
    remainingRevisions: number;
    hasUnlimitedRevisions: boolean;
    collaborationId: string;
    pinnedComments: PinComment[];
    generalComment: string;
    onClick?: () => void;
    files: CollaborationFile[];
    feedbackView: boolean;
}

export function DesignRevisionFeedbackPanel(props: DesignRevisionFeedbackPanelProps) {
    return (
        <FlexBox flexDirection="column" gap={'between-sections'} paddingX="7" paddingY="10">
            <DesignRevisionMeta
                revisionVersion={props.version}
                remainingRevisions={props.remainingRevisions}
                hasUnlimitedRevisions={props.hasUnlimitedRevisions}
            />
            {props.generalComment.length > 0 && (
                <TextSection heading={__('General comment')} text={props.generalComment} />
            )}
            {props.pinnedComments.length > 0 && <PinCommentList comments={props.pinnedComments} />}
            {props.files.length > 0 && <ReferenceImageSection files={props.files} />}
            {!props.feedbackView && <TextSection heading={__('ID')} text={props.collaborationId} />}
            {!props.feedbackView && (
                <Box>
                    <Button onClick={props.onClick} standardMode width="standard">
                        {__('Back')}
                    </Button>
                </Box>
            )}
        </FlexBox>
    );
}
