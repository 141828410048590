import { useLocation, useNavigate } from 'react-router-dom';
import { COLLABORATE_BASE_PATH } from '../ui/lib/constants';
import { useDeliveryVariationRouteParams } from './useDeliveryVariationRouteParams';

export function useVersion(subRoute: string | null) {
    const { deliveryVersionId, collaborationId } = useDeliveryVariationRouteParams();
    const navigate = useNavigate();
    const location = useLocation();
    const isFeedbackRoute = location.pathname.includes('/feedback');

    return {
        version: deliveryVersionId,
        setVersion: (version: number) => {
            if (isFeedbackRoute) {
                return navigate(
                    `${COLLABORATE_BASE_PATH}/feedback/${collaborationId}/delivery/${version}`
                );
            }
            navigate(
                `${COLLABORATE_BASE_PATH}/${collaborationId}/delivery/${version}${
                    subRoute ? `/${subRoute}` : ''
                }`
            );
        },
    };
}
