/* eslint-disable */

import * as Apollo from '@apollo/client';
import { DocumentNode } from 'graphql';
import * as Types from '@99designs/graph-utils/types';

export type PrintProductConfiguratorQueryVariables = Types.Exact<{
    input: Types.PrintProductConfiguratorInput;
}>;

export type PrintProductConfiguratorQuery = {
    __typename: 'Query';
    printProductConfigurator: {
        __typename: 'PrintProductConfigurator';
        productOptions: Array<{
            __typename: 'PrintProductOption';
            key: string;
            name: string;
            summaryName: string;
            values: Array<{
                __typename: 'ProductOptionValue';
                key: string;
                name: string;
                imageUrl: Types.Maybe<string>;
                isCompatible: boolean;
            }>;
        }>;
    };
};

export const PrintProductConfiguratorDocument: DocumentNode = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'PrintProductConfigurator' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'PrintProductConfiguratorInput' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'printProductConfigurator' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'input' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'productOptions' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'summaryName' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'values' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'key' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'name' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'imageUrl',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'isCompatible',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
};

/**
 * __usePrintProductConfiguratorQuery__
 *
 * To run a query within a React component, call `usePrintProductConfiguratorQuery` and pass it any options that fit your needs.
 * When your component renders, `usePrintProductConfiguratorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePrintProductConfiguratorQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePrintProductConfiguratorQuery(
    baseOptions: Apollo.QueryHookOptions<
        PrintProductConfiguratorQuery,
        PrintProductConfiguratorQueryVariables
    >
) {
    return Apollo.useQuery<PrintProductConfiguratorQuery, PrintProductConfiguratorQueryVariables>(
        PrintProductConfiguratorDocument,
        baseOptions
    );
}
export function usePrintProductConfiguratorLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        PrintProductConfiguratorQuery,
        PrintProductConfiguratorQueryVariables
    >
) {
    return Apollo.useLazyQuery<
        PrintProductConfiguratorQuery,
        PrintProductConfiguratorQueryVariables
    >(PrintProductConfiguratorDocument, baseOptions);
}
export type PrintProductConfiguratorQueryHookResult = ReturnType<
    typeof usePrintProductConfiguratorQuery
>;
export type PrintProductConfiguratorLazyQueryHookResult = ReturnType<
    typeof usePrintProductConfiguratorLazyQuery
>;
export type PrintProductConfiguratorQueryResult = Apollo.QueryResult<
    PrintProductConfiguratorQuery,
    PrintProductConfiguratorQueryVariables
>;
