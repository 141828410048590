const domainLookup: { [key: string]: string } = {
    'da-dk': 'https://www.vistaprint.dk',
    'de-at': 'https://www.vistaprint.at',
    'de-ch': 'https://www.vistaprint.ch',
    'de-de': 'https://www.vistaprint.de',
    'en-au': 'https://www.vistaprint.com.au',
    'en-ca': 'https://www.vistaprint.ca',
    'en-gb': 'https://www.vistaprint.co.uk',
    'en-ie': 'https://www.vistaprint.ie',
    'en-in': 'https://www.vistaprint.in',
    'en-nz': 'https://www.vistaprint.co.nz',
    'en-sg': 'https://www.vistaprint.sg',
    'en-us': 'https://www.vistaprint.com',
    'es-es': 'https://www.vistaprint.es',
    'es-us': 'https://www.vistaprint.com',
    'fi-fi': 'https://www.vistaprint.fi',
    'fr-be': 'https://www.vistaprint.be',
    'fr-ca': 'https://www.vistaprint.ca',
    'fr-ch': 'https://www.vistaprint.ch',
    'fr-fr': 'https://www.vistaprint.fr',
    'it-ch': 'https://www.vistaprint.ch',
    'it-it': 'https://www.vistaprint.it',
    'ja-jp': 'https://www.vistaprint.jp',
    'nb-no': 'https://www.vistaprint.no',
    'nl-be': 'https://www.vistaprint.be',
    'nl-nl': 'https://www.vistaprint.nl',
    'pt-pt': 'https://www.vistaprint.pt',
    'sv-se': 'https://www.vistaprint.se',
};

export function domainForLocale(locale: string): string {
    return domainLookup[locale.toLowerCase()] ?? '';
}
