import { Link, useNavigate } from 'react-router-dom';
import { Button, Divider, Typography, tokens } from '@vp/swan';
import { FormattedReactMessage } from '@99designs/design-services-common';
import { __ } from '@99designs/i18n';
import { useCollaborationContext } from '../../../context';
import { domainForLocale } from '../../../util/domainLookup';
import { COLLABORATE_BASE_PATH } from '../constants';
import { CollaborationEvent } from './CollaborationEvent';

export interface RevisionRequestEventProps {
    message?: string;
    deliveryVersionId: number;
    maxRevisionRequests: number;
    collaborationPublicId: string;
    hasUnlimitedRevisions: boolean;
}

export const RevisionRequestEvent: React.FC<RevisionRequestEventProps> = ({
    message,
    deliveryVersionId,
    maxRevisionRequests,
    collaborationPublicId,
    hasUnlimitedRevisions,
}) => {
    const { locale, isFreelance, viewerRole } = useCollaborationContext();
    const navigate = useNavigate();
    const isFreelanceDesigner = isFreelance && viewerRole === 'VIEWER_ROLE_DESIGNER';

    const onClickViewFeedback = () => {
        if (isFreelanceDesigner) {
            const vistaprintBaseUrl = domainForLocale(locale);
            window.open(
                `${vistaprintBaseUrl}${COLLABORATE_BASE_PATH}/feedback/${collaborationPublicId}/delivery/${deliveryVersionId}`,
                '_blank'
            );
        } else {
            navigate(
                `${COLLABORATE_BASE_PATH}/${collaborationPublicId}/delivery/${deliveryVersionId}/revision`
            );
        }
    };

    const revisionRequestCopy = hasUnlimitedRevisions
        ? __(
              'Revision request successfully submitted for <Link>Version {deliveryVersionId}</Link>. You will be notified by email when your design is ready.'
          )
        : __(
              'Revision request successfully submitted for <Link>Version {deliveryVersionId}</Link>. You have {remainingRevisions} remaining. You will be notified by email when your design is ready.'
          );

    return (
        <CollaborationEvent alignmentDirection="right" color={tokens.SwanSemColorBgAccent}>
            {message && (
                <>
                    <Typography style={{ whiteSpace: 'pre-wrap' }}>
                        <i>{message}</i>
                    </Typography>
                    <Divider my={'5'} />
                </>
            )}
            <Typography marginTop={'3'}>
                <FormattedReactMessage
                    message={revisionRequestCopy}
                    replacements={{
                        Link: !isFreelanceDesigner ? (
                            <Link
                                to={`${COLLABORATE_BASE_PATH}/${collaborationPublicId}/delivery/${deliveryVersionId}`}
                            />
                        ) : (
                            <span />
                        ),
                    }}
                    values={{
                        deliveryVersionId: deliveryVersionId,
                        remainingRevisions: maxRevisionRequests - deliveryVersionId,
                    }}
                />
            </Typography>
            <Button
                marginY={'3'}
                fontSkin={'body-standard-bold'}
                width="full-width"
                onClick={onClickViewFeedback}
            >
                {__('View feedback')}
            </Button>
        </CollaborationEvent>
    );
};
